<template>
    <div class="card mx-2 my-2 pt-2" :id="index + '-pointCard'" style="width: 47%">
        <button @click="deletePointCard()" type="button" class="btn btn-sm bg-red-500 ml-1 border-none">Remove Point</button>

        <label class="label"><span class="label-text text-black">Title</span></label>
        <input v-model="servicePointContent.servicePointTitle" type="text" class="input input-md input-bordered w-full">

        <label class="label"><span class="label-text text-black">Description</span></label>
        <textarea v-model="servicePointContent.servicePointDescription" type="text" rows="4" class="textarea textarea-bordered w-full h-36"></textarea>

        <label class="label"><span class="label-text text-black">Attachment</span></label>
        <div class="flex" v-if="!checkIsFile">
            <div class="flex-initial w-70">
                <img :src="servicePointContent.servicePointAttachmentSrc" alt="">
            </div>
            <div class="flex-initial w-26 align-middle">
                <button @click="removeImage()" type="button" class="btn btn-sm bg-red-500 ml-1 border-none">Remove</button>
            </div>
        </div>
        <DragDropImage v-if="checkIsFile" @changed="handleImage" :max="1" class="text-black" clearAll="Clear All" maxError="Maximum one file only"/>
    </div>
</template>

<script>
import DragDropImage from '../../components/DragDropImage.vue'

export default {
    props: ['index','servicePointContent'],
    components: { DragDropImage },
    data(){
        return {

        }
    },
    methods: {
        removeImage(){
            this.servicePointContent.servicePointAttachmentSrc = null
        },
        handleImage(files){
            if (files[0] != null) {
                var reader = new FileReader();
                reader.readAsDataURL(files[0]);

                reader.onload = () => {
                    this.servicePointContent.servicePointAttachmentSrc = reader.result;
                };
            }
        },
        deletePointCard(){
            this.removeImage()
            this.$emit('deletePointCard', this.index)
        },
    },
    computed: {
        checkIsFile(){
            if((this.servicePointContent.servicePointAttachmentSrc instanceof File) || this.servicePointContent.servicePointAttachmentSrc == 'null' || this.servicePointContent.servicePointAttachmentSrc == 'undefined' || this.servicePointContent.servicePointAttachmentSrc == null){ // show dragDropImage when the value is a file or null or empty
                return true
            }else{ // of none of those condition meet, means the variable contains an url in server
                return false
            }
        },
    }
}
</script>

<style scoped>
.container{
    height: unset; /* to override <UploadImage> height*/
}

@media (max-width: 981px) {
    .card {
        width: 100% !important;
    }
}
</style>